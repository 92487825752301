import React from "react";
import Layout from "../layouts/layout.js";
import SEO from "../components/Seo";
import tw from "twin.macro";
import Header from "../components/Header.js";
import { H1, P, Ol, Li } from "../utils/helpers.js";

const PageWrapper = tw.section`
  px-4 md:px-8 my-8 max-w-3xl mx-auto
`;

const H3 = tw.h3`
text-xl font-bold my-6
`;

const H4 = tw.h4`
text-lg font-medium italic mt-4 mb-2
`;

export default function Home() {
  return (
    <Layout>
      <SEO title="Terms of Use" />

      <Header bgImage="https://source.unsplash.com/pCVi2aDTQaM/1600x900">
        <H1>Terms of Use</H1>
      </Header>

      <PageWrapper>
        <P>
          <strong>Last modified and effective: July 16, 2020</strong>
        </P>
        <H3>1. Contractual Relationship</H3>
        <P>
          These Terms of Use (&quot;Terms&quot;) govern your access and use,
          from within the United States and its territories and possessions, of
          the applications, websites, content, products, subscriptions and
          services (the &quot;Services,&quot; as more fully defined below in
          Section 3) made available in the United States and its territories and
          possessions by Cardinal Fez, Inc. (dba Jeevz) and its affiliates,
          subsidiaries, parents, successors and assigns, and each of its and
          their respective officers, directors, employees, agents, and
          shareholders (collectively, &quot;Jeevz&quot;). References to “you”
          and “your” shall be deemed to include the members of your household
          (spouse, parent or child residing in the same home as you) listed on
          your Account. PLEASE READ THESE TERMS CAREFULLY AS THEY CONSTITUTE A
          LEGAL AGREEMENT BETWEEN YOU AND JEEVZ.
        </P>
        <P>
          By and upon creating an Account or accessing or using the Services,
          you confirm your agreement to be bound by these Terms. If you do not
          agree to these Terms, you may not access or use the Services. These
          Terms expressly supersede any prior agreements or arrangements with
          you. Jeevz may immediately terminate these Terms or any Account (as
          defined below), Subscription (as defined below) or Services with
          respect to you, or generally cease offering or deny access to your
          Account, Subscription or the Services or any portion thereof, at any
          time for any reason.
        </P>
        <P>
          IMPORTANT: PLEASE REVIEW THE ARBITRATION AGREEMENT SET FORTH BELOW
          CAREFULLY, AS IT WILL REQUIRE YOU TO RESOLVE DISPUTES WITH JEEVZ ON AN
          INDIVIDUAL BASIS THROUGH FINAL AND BINDING ARBITRATION, NOT AS A
          PLAINTIFF OR CLASS MEMBER IN ANY CLASS, GROUP OR REPRESENTATIVE ACTION
          OR PROCEEDING. BY ENTERING THIS AGREEMENT, YOU EXPRESSLY ACKNOWLEDGE
          THAT YOU HAVE READ AND UNDERSTAND ALL OF THE TERMS OF THIS AGREEMENT
          AND HAVE TAKEN TIME TO CONSIDER THE CONSEQUENCES OF THIS IMPORTANT
          DECISION. IF YOU DO NOT AGREE TO BE BOUND BY THE TERMS AND CONDITIONS
          OF THIS AGREEMENT, YOU MAY NOT USE OR ACCESS THE SERVICES OR ANY OTHER
          SERVICES PROVIDED THROUGH JEEVZ. IF YOU USE JEEVZ OR THE SERVICES IN
          ANOTHER COUNTRY OR JURISDICTION, YOU AGREE TO BE SUBJECT TO
          JEEVZ&#39;S TERMS OF SERVICE FOR THAT COUNTRY OR JURISDICTION.
        </P>
        <P>
          Supplemental and/or additional terms and conditions may apply to
          certain Accounts, Subscriptions and/or Services, such as policies,
          statements, FAQs or related information for a particular Service,
          Subscription, event, program, activity or promotion, and such
          supplemental and/or additional terms and conditions will be disclosed
          to you in separate region-specific disclosures whether through
          www.Jeevz.com, the Applications (as defined below), e-mail, text
          message or other communication, or in connection with the applicable
          Service(s). Supplemental and/or additional terms and conditions are in
          addition to, and shall be deemed a part of, the Terms for the purposes
          of the applicable Account, Subscription or Service, as applicable.
          Supplemental and/or additional terms and conditions shall prevail over
          these Terms in the event of a conflict with respect to the applicable
          Account, Subscription or Service.
        </P>
        <P>
          Jeevz may amend the Terms (and any supplemental and/or additional
          terms) from time to time. Amendments will be effective thirty (30)
          days after Jeevz’s posting or communication of such updated Terms at
          this location, within your Account or the Applications, through
          e-mail, text message or other communication, or in the relevant
          policies, statements or supplemental or additional terms. Your
          continued access or use of the Services after such posting or
          communication confirms your consent to be bound by the Terms, as
          amended or supplemented. If you do not agree to, or cannot comply
          with, the Terms as amended or supplemented, you must stop using the
          Applications and Services and Jeevz may terminate your Account,
          Subscription and/or Service.
        </P>
        <P>
          Jeevz’s collection and use of personal information in connection with
          the Services is described below and in Jeevz’s Privacy Policy located
          at <a href="https://jeevz.com/privacy">https://jeevz.com/privacy</a>.
        </P>
        <H3>2. Arbitration Agreement</H3>
        <P>
          By agreeing to the Terms, you agree that you are required to resolve
          any claim that you may have against Jeevz on an individual basis in
          arbitration, as set forth in this Arbitration Agreement. This will
          preclude you from bringing any class, collective, or representative
          action against Jeevz, and also preclude you from participating in or
          recovering relief under any current or future class, collective,
          consolidated, or representative action brought against Jeevz by
          someone else.
        </P>
        <H4>Agreement to Binding Arbitration Between You and Jeevz.</H4>
        <P>
          You and Jeevz agree that any dispute, claim or controversy arising out
          of or relating to (a) these Terms or the existence, breach,
          termination, enforcement, interpretation or validity thereof, or (b)
          your access to or use of the Services at any time, whether before or
          after the date you agreed to the Terms, will be settled by binding
          arbitration between you and Jeevz, and not in a court of law.
          Notwithstanding the foregoing, where you allege claims of sexual
          assault or sexual harassment occurring in connection with your use of
          the Services, you may elect to bring those claims in a court of
          competent jurisdiction instead of arbitration. Jeevz agrees to honor
          your election of forum with respect to your individual sexual assault
          or sexual harassment claim but in so doing does not waive the
          enforceability of this Arbitration Agreement as to any other provision
          (including, but not limited to, the waivers provided in the following
          paragraph, which will continue to apply in court and arbitration),
          controversy, claim or dispute.
        </P>
        <P>
          You acknowledge and agree that you and Jeevz are each waiving the
          right to a trial by jury or to participate as a plaintiff or class
          member in any purported class action or representative proceeding.
          Unless both you and Jeevz otherwise agree in writing, any arbitration
          will be conducted only on an individual basis and not in a class,
          collective, consolidated, or representative proceeding. However, you
          and Jeevz each retain the right to bring an individual action in small
          claims court and the right to seek injunctive or other equitable
          relief in a court of competent jurisdiction to prevent the actual or
          threatened infringement, misappropriation or violation of a
          party&#39;s copyrights, trademarks, trade secrets, patents or other
          intellectual property rights.
        </P>
        <P>
          <H4>Rules and Governing Law</H4>
        </P>
        <P>
          The arbitration will be administered by the American Arbitration
          Association (&quot;AAA&quot;) in accordance with the AAA’s Consumer
          Arbitration Rules and the Supplementary Procedures for Consumer
          Related Disputes (the &quot;AAA Rules&quot;) then in effect, except as
          modified by this Arbitration Agreement. The AAA Rules are available at
          <a href="https://www.adr.org/">www.adr.org</a> or by calling the AAA
          at 1-800-778-7879.
        </P>
        <P>
          The parties agree that the arbitrator (&quot;Arbitrator&quot;), and
          not any federal, state, or local court or agency, shall have exclusive
          authority to resolve any disputes relating to the interpretation,
          applicability, enforceability or formation of this Arbitration
          Agreement, including any claim that all or any part of this
          Arbitration Agreement is void or voidable. The Arbitrator shall also
          be responsible for determining all threshold arbitrability issues,
          including issues relating to whether the Terms are unconscionable or
          illusory and any defense to arbitration, including waiver, delay,
          laches, or estoppel.
        </P>
        <P>
          Notwithstanding any choice of law or other provision in the Terms, the
          parties agree and acknowledge that this Arbitration Agreement
          evidences a transaction involving interstate commerce and that the
          Federal Arbitration Act, 9 U.S.C. § 1 et seq. (&quot;FAA&quot;), will
          govern its interpretation and enforcement and proceedings pursuant
          thereto. It is the intent of the parties that the FAA and AAA Rules
          shall preempt all state laws to the fullest extent permitted by law.
          If the FAA and AAA Rules are found to not apply to any issue that
          arises under this Arbitration Agreement or the enforcement thereof,
          then that issue shall be resolved under the laws of the state of
          Florida.
        </P>
        <H4>Process.</H4>
        <P>
          A party who desires to initiate arbitration must provide the other
          party with a written Demand for Arbitration as specified in the AAA
          Rules. (The AAA provides a form Demand for Arbitration - Consumer
          Arbitration Rules at <a href="https://www.adr.org/">www.adr.org</a> or
          by calling the AAA at 1-800-778-7879). The Arbitrator will be either
          (1) a retired judge or (2) an attorney specifically licensed to
          practice law in the state of California and will be selected by the
          parties from the AAA&#39;s roster of consumer dispute arbitrators. If
          the parties are unable to agree upon an Arbitrator within seven (7)
          days of delivery of the Demand for Arbitration, then the AAA will
          appoint the Arbitrator in accordance with the AAA Rules.
        </P>
        <H4>Location and Procedure.</H4>
        <P>
          Unless you and Jeevz otherwise agree, the arbitration will be
          conducted in the county where you reside. If your claim does not
          exceed $10,000, then the arbitration will be conducted solely on the
          basis of documents you and Jeevz submit to the Arbitrator, unless you
          request a hearing or the Arbitrator determines that a hearing is
          necessary. If your claim exceeds $10,000, your right to a hearing will
          be determined by the AAA Rules. Subject to the AAA Rules, the
          Arbitrator will have the discretion to direct a reasonable exchange of
          information by the parties, consistent with the expedited nature of
          the arbitration.
        </P>
        <H4>Arbitrator's Decision.</H4>
        <P>
          The Arbitrator will render an award within the time frame specified in
          the AAA Rules. Judgment on the arbitration award may be entered in any
          court having competent jurisdiction to do so. The Arbitrator may award
          declaratory or injunctive relief only in favor of the claimant and
          only to the extent necessary to provide relief warranted by the
          claimant&#39;s individual claim. An Arbitrator’s decision shall be
          final and binding on all parties. An Arbitrator’s decision and
          judgment thereon shall have no precedential or collateral estoppel
          effect. If you prevail in arbitration you will be entitled to an award
          of attorneys&#39; fees and expenses, to the extent provided under
          applicable law. Jeevz will not seek, and hereby waives all rights
          Jeevz may have under applicable law to recover attorneys&#39; fees and
          expenses if Jeevz prevails in arbitration.
        </P>
        <H4>Fees.</H4>
        <P>
          Your responsibility to pay any AAA filing, administrative and
          arbitrator fees will be solely as set forth in the AAA Rules. However,
          if your claim for damages does not exceed $75,000, Jeevz will pay all
          such fees, unless the Arbitrator finds that either the substance of
          your claim or the relief sought in your Demand for Arbitration was
          frivolous or was brought for an improper purpose (as measured by the
          standards set forth in Federal Rule of Civil Procedure 11(b)).
        </P>
        <H4>Changes.</H4>
        <P>
          Notwithstanding the provisions in Section 1 above, regarding consent
          to be bound by amendments to these Terms, if Jeevz changes this
          Arbitration Agreement after the date you first agreed to the Terms (or
          to any subsequent changes to the Terms), you may reject any such
          change by providing Jeevz written notice of such rejection within 30
          days of the date such change became effective, as indicated in the
          &quot;Effective&quot; date above. This written notice must be provided
          either (a) by first class mail, pre-paid post or hand delivery to
          Cardinal Fez, Inc., Attn: Secretary, 501 E. Las Olas Blvd., Suite 300,
          Fort Lauderdale, Florida 33301, or (b) by email from the email address
          associated with your Account to: concierge@jeevz.com. In order to be
          effective, the notice must include your full name and clearly indicate
          your intent to reject changes to this Arbitration Agreement. By
          rejecting changes, you are agreeing that you will arbitrate any
          dispute between you and Jeevz in accordance with the provisions of
          this Arbitration Agreement as of the date you first agreed to the
          Terms (or to any subsequent changes to the Terms).
        </P>
        <H4>Severability and Survival.</H4>
        <P>
          If any portion of this Arbitration Agreement is found to be
          unenforceable or unlawful for any reason, (1) the unenforceable or
          unlawful provision shall be severed from these Terms; (2) severance of
          the unenforceable or unlawful provision shall have no impact
          whatsoever on the remainder of the Arbitration Agreement or the
          parties’ ability to compel arbitration of any remaining claims on an
          individual basis pursuant to the Arbitration Agreement; and (3) to the
          extent that any claims must therefore proceed on a class, collective,
          consolidated, or representative basis, such claims must be litigated
          in a civil court of competent jurisdiction and not in arbitration, and
          the parties agree that litigation of those claims shall be stayed
          pending the outcome of any individual claims in arbitration.
        </P>
        <H3>3. The Services</H3>
        <P>
          The Services comprise mobile applications, websites and similar or
          related means (the &quot;Applications&quot;) through which Jeevz
          provides a personalized subscription service that allows its users to
          arrange and schedule the use of an independent personal driver to
          provide transportation in such user’s own motor vehicle. Unless
          otherwise agreed by Jeevz in a separate written agreement with you,
          the Services are made available solely for your personal,
          noncommercial use. You authorize Jeevz to match you with a driver
          based on factors such as your location, the estimated time to pickup,
          your destination, user preferences, and platform efficiency, and to
          cancel an existing match and rematch based on the same considerations.
          Any decision by you to request or accept Services is a decision made
          in your sole discretion. You understand and agree that Jeevz provides
          a technology platform connecting you with independent personal drivers
          and that Jeevz itself does not provide transportation, logistics
          and/or delivery services, has no responsibility or liability for the
          acts or omissions of any such driver, and will not assess or guarantee
          the suitability, legality or ability of any driver or his or her
          actions. YOU ACKNOWLEDGE THAT YOUR ABILITY TO OBTAIN TRANSPORTATION
          SERVICES THROUGH THE USE OF THE APPLICATIONS AND SERVICES DOES NOT
          ESTABLISH JEEVZ AS A TAXI, LIMOUSINE OR CHAUFFEUR SERVICE, PROVIDER OF
          TRANSPORTATION, LOGISTICS OR DELIVERY SERVICES OR AS A TRANSPORTATION
          CARRIER OR COMMON CARRIER.
        </P>
        <H4>License.</H4>
        <P>
          Subject to your compliance with these Terms, Jeevz grants you a
          limited, non-exclusive, non-sublicensable, revocable, non-transferable
          license to: (i) access and use the Applications and Services on your
          personal device solely in connection with your use of the Services;
          and (ii) access and use any content, information and related materials
          that may be made available through the Applications and Services, in
          each case solely for your personal, noncommercial use. Any rights not
          expressly granted herein are reserved by Jeevz and Jeevz&#39;s
          licensors. Jeevz may revoke and/or terminate the foregoing license
          with respect to any aspect of the Applications or Services at any
          time, for any or no reason.
        </P>
        <H4>Restrictions.</H4>
        <P>
          You may not: (i) remove any copyright, trademark or other proprietary
          notices from any portion of the Applications or Services; (ii)
          reproduce, modify, prepare derivative works based upon, distribute,
          license, lease, sell, resell, transfer, publicly display, publicly
          perform, transmit, stream, broadcast or otherwise exploit the
          Applications or Services except as expressly permitted by Jeevz; (iii)
          decompile, reverse engineer or disassemble the Applications or
          Services except as may be permitted by applicable law; (iv) link to,
          mirror or frame any portion of the Applications or Services; (v) cause
          or launch any programs or scripts for the purpose of scraping,
          indexing, surveying, or otherwise data mining any portion of the
          Applications or Services or unduly burdening or hindering the
          operation and/or functionality of any aspect of the Applications or
          Services; (vi) attempt to gain unauthorized access to or impair or
          harm any aspect of the Applications or Services, the interests or
          property of Jeevz or other users of the Applications or Services, or
          their related systems or networks; (vii) provide your username and
          password used to access the Applications or Services to any third
          party; (viii) circumvent any technological measures employed by or on
          behalf of Jeevz to protect the Applications and Services; or (ix) aid
          or encourage any third party to engage in any activity that would
          constitute a breach of these Terms.
        </P>
        <P>
          ANY USE OF THE APPLICATIONS OR SERVICES NOT SPECIFICALLY PERMITTED
          UNDER THESE TERMS IS STRICTLY PROHIBITED.
        </P>
        <H4>Provision of the Services.</H4>
        <P>
          You acknowledge that portions of the Services may be made available
          under Jeevz’s various current or future brands or request options
          associated with transportation, including the transportation request
          brand currently referred to as &quot;Jeevz&quot;. You also acknowledge
          that the Services may be made available under such brands or request
          options by or in connection with: (i) certain of Jeevz&#39;s
          subsidiaries and affiliates; or (ii) independent contractors (e.g.,
          drivers).
        </P>
        <H4>Third Party Services and Content.</H4>
        <P>
          The Services may be made available or accessed in connection with
          third party services and content (including advertising) that Jeevz
          does not control. You acknowledge that different terms of use and
          privacy policies may apply to your use of such third party services
          and content. Jeevz does not endorse such third party services and
          content and in no event shall Jeevz be responsible or liable for any
          products or services of such third party providers. Additionally,
          certain third party mobile or personal device manufacturers and brands
          will be third-party beneficiaries to these Terms if you access the
          Services using Applications developed for their respective operating
          systems or powered mobile devices. These third-party beneficiaries are
          not parties to these Terms and are not responsible for the provision
          or support of the Services in any manner. Your access to the Services
          using these devices is subject to terms set forth in the applicable
          third party beneficiary&#39;s terms of service.
        </P>
        <H4>Ownership.</H4>
        <P>
          The Services and all rights therein are and shall remain Jeevz&#39;s
          property or the property of Jeevz’s licensors. Neither these Terms nor
          your use of the Services convey or grant to you any rights: (i) in or
          related to the Services except for the limited license granted above;
          or (ii) to use or reference in any manner Jeevz&#39;s company names,
          logos, product and service names, trademarks or services marks or
          those of Jeevz&#39;s licensors.
        </P>
        <H3>4. Access, Eligibility and Use of the Services</H3>

        <H4>User Accounts.</H4>
        <P>
          In order to use most aspects of the Services, you must register for
          and maintain an active personal user Services account
          (&quot;Account&quot;). The Services may only be used by individuals
          who have the right and authority to enter into this Agreement, and are
          fully able and competent to satisfy the terms, conditions, and
          obligations herein. The Services are not available if your Account (or
          any previous Account) has been temporarily or permanently deactivated,
          suspended, terminated or cancelled. Except for members of the same
          household (spouse, parent or child residing in the same home as you)
          listed on your Account, you may not allow other persons to use your
          Account, and you agree that you are the sole authorized user of your
          Account. You may only create one Account, and Jeevz reserves the right
          to deactivate any additional or duplicate accounts.
        </P>
        <P>
          To establish and register an Account and/or use the Services, you must
          be at least 18 years of age, or the age of legal majority in your
          jurisdiction (if different than 18), a resident of the relevant
          authorized jurisdiction (currently only the states within the United
          States of America and its territories), have all applicable rights and
          authority to enter into these Terms and grant Jeevz the rights granted
          herein, and have read, understood and agree to be bound by these
          Terms. Account registration requires you to submit to Jeevz certain
          personal information, such as your name, address, e-mail address,
          mobile phone number, age, driver’s license, proof of insurance and
          vehicle registration and ownership, vehicle details (such as make,
          model, color, license tag number and VIN number), vehicle inspection
          reports or representations related to the condition of your vehicle,
          and related information, as well as Payment Method(s) (as defined
          below) supported by Jeevz.
        </P>
        <P>
          You agree to maintain accurate, complete, and up-to-date information
          in your Account. Your failure to maintain accurate, complete, and
          up-to-date Account information, including having an invalid or expired
          payment method, driver’s license, vehicle insurance, registration or
          insurance on file, may result in your inability to access or use the
          Services. You are responsible for all activity that occurs under your
          Account, and you agree to maintain the security and secrecy of your
          Account username and password and to monitor your account to prevent
          unauthorized use at all times. Should you suspect that any
          unauthorized party may be using your Account, username or password,
          you will notify Jeevz immediately. Jeevz will not be liable and you
          may be liable for losses, damages, liability, expenses, and fees
          incurred by Jeevz or a third party arising from someone else using
          your Account, regardless of whether you have notified Jeevz of such
          unauthorized use.
        </P>
        <P>
          Any breach or reasonable grounds to suspect a breach of the foregoing
          may result in the temporary or permanent deactivation, suspension,
          termination or cancellation of your Account in Jeevz’s sole
          discretion.
        </P>
        <H4>Promotions, Referrals, and Loyalty Programs.</H4>
        <P>
          Jeevz, at its sole discretion, may make available promotions, referral
          programs and loyalty programs with different features to you, any
          other users or prospective users. These promotions and programs,
          unless made to you, shall have no bearing whatsoever on your Agreement
          or relationship with Jeevz. Jeevz reserves the right to withhold or
          deduct credits or benefits obtained through a promotion or program in
          the event that Jeevz determines or believes that the redemption of the
          promotion or receipt of the credit or benefit was in error,
          fraudulent, illegal, or in violation of the applicable promotion or
          program terms or this Agreement. Jeevz reserves the right to
          terminate, discontinue or cancel any promotions or programs at any
          time and in its sole discretion without notice to you.
        </P>
        <P>
          Jeevz&#39;s referral program provides you with incentives to refer
          your friends and family to become new users of the Applications and
          Services (the “Referral Program&quot;). Your participation in the
          Referral Program is subject to this Agreement and the additional
          Referral Program rules and regulations, as applicable.
        </P>
        <H4>User Requirements and Conduct.</H4>
        <P>
          The Service is not available for use by persons under the age of 18.
          Other than members of your household (spouse, parent or child residing
          in the same home as you) listed on your Account, you may not authorize
          third parties to use your Account, and you may not allow persons under
          the age of 18 to receive the Services, unless they are accompanied by
          you, and you are responsible for any activity that occurs through your
          Account, on the Application or through the Services. You may not
          assign or otherwise transfer your Account to any other person or
          entity. You agree to comply with all applicable laws when accessing or
          using the Services, and you may only access or use the Services for
          lawful purposes (e.g., no transport of unlawful or hazardous
          materials). In certain instances you may be asked to provide, among
          other things, proof of identity or other method of identity
          verification, proof of vehicle insurance, proof of vehicle
          registration and ownership or proof of vehicle condition, to access or
          use the Services, and you agree that you may be denied access to or
          use of the Services if you refuse to provide the same or it is
          insufficient in Jeevz’s sole discretion. Any breach of the foregoing
          may result in the temporary or permanent deactivation, termination or
          cancellation of your Account in Jeevz’s sole discretion.
        </P>
        <P>
          Jeevz reserves the right to refuse to provide the Services to you or
          to any other person if, in the Jeevz’s or the driver&#39;s sole
          discretion, such Service would be unsafe because: (a) you or such
          other person is armed, belligerent, violent, verbally or physically
          threatening or abusive, or intoxicated or under the influence of drugs
          or other substances; (b) the vehicle is unsafe to drive; or (c) any
          other facts or circumstances relevant to your, any other person’s or
          the driver&#39;s safety, as determined in driver&#39;s sole
          discretion.
        </P>
        <H4>Restricted Activities.</H4>
        <P>
          With respect to your use of the Applications and Services, you agree,
          in addition to the other restrictions set out in this Agreement and
          any other applicable Jeevz policy or terms, that you will not:
        </P>
        <Ol>
          <Li>
            stalk, threaten, or otherwise harass any person, or carry any
            weapons, or otherwise engage in any conduct that harms or would be
            expected to harm other users, drivers, Jeevz employees or the
            community;
          </Li>
          <Li>
            use the Applications or Services for unlawful purposes, sending or
            storing any unlawful material, or otherwise violate or attempt to
            violate any law, statute, rule, permit, ordinance or regulation,
            including any seat belt and open container, substance and contraband
            laws;
          </Li>
          <Li>
            engage in inappropriate or unnecessary physical, verbal or other
            contact or other abusive behavior with the driver, other users or
            Jeevz employees before, during or after the Services;
          </Li>
          <Li>
            interfere with or disrupt the Applications or the servers or
            networks connected to the Applications;
          </Li>
          <Li>
            post Information or User Content (each as defined below) or interact
            on the Applications or with the Services in a manner which is
            defamatory, libelous, hateful, discriminatory, racist, violent,
            abusive, profane, obscene, pornographic, sexually explicit, illegal,
            unlawful, or otherwise offensive, as determined by Jeevz in its sole
            discretion, whether or not the same may be protected by law;
          </Li>
          <Li>
            use the Applications or Services in any way that is not explicitly
            authorized by Jeevz or infringes any third party’s rights,
            including: intellectual property rights, copyright, patent,
            trademark, trade secret or other proprietary rights or rights of
            publicity or privacy;
          </Li>
          <Li>
            post, email or otherwise transmit any malicious code, files or
            programs designed to interrupt, damage, destroy or limit the
            functionality of the Applications, Services or any computer software
            or hardware or telecommunications equipment or surreptitiously
            intercept or expropriate any system, data or personal information;
          </Li>
          <Li>
            use another user’s Account, impersonate any person or entity, or
            forge headers or otherwise manipulate identifiers, including to
            disguise the origin of any information or content transmitted
            through the Applications, Services or otherwise;
          </Li>
          <Li>
            “frame” or “mirror” any part of the Applications, without our prior
            written authorization from Jeevz or use meta tags or code or other
            devices containing any reference to Jeevz in order to direct any
            person to any other web site for any purpose;
          </Li>
          <Li>
            modify, adapt, translate, reverse engineer, decipher, decompile or
            otherwise disassemble any portion of the Applications;
          </Li>
          <Li>
            rent, lease, lend, sell, redistribute, license or sublicense the
            Applications or access to any portion of the Applications;
          </Li>
          <Li>
            use any robot, spider, site search/retrieval application, or other
            manual or automatic device or process to retrieve, index, scrape,
            “data mine”, or in any way reproduce or circumvent the navigational
            structure or presentation of the Applications or its contents;
          </Li>
          <Li>link directly or indirectly to any other web sites;</Li>
          <Li>
            copy or distribute any part of the Applications or Services or any
            content displayed through the same for republication;
          </Li>
          <Li>
            transfer or sell your Account, password and/or identification, or
            any other Information or User Content (including yours or any other
            Jeevz user) to any other party, or transfer or re-sell the Services
            except as expressly permitted herein;
          </Li>
          <Li>
            discriminate against or harass anyone on the basis of race, national
            origin, religion, gender, gender identity, physical or mental
            disability, medical condition, marital status, age or sexual
            orientation;
          </Li>
          <Li>
            violate any of the Referral Program rules if you participate in the
            Referral Program, or violate or abuse any promotional or credit
            program or system;
          </Li>
          <Li>
            use the Applications or Services to cause nuisance, annoyance or
            inconvenience, or personal injury or property damage to any other
            party;
          </Li>
          <Li>
            use the Applications or Services, or any content accessible through
            the same, for any commercial purpose, unless Jeevz has given you
            prior permission to do so in writing; or
          </Li>
          <Li>
            attempt to undertake any of the foregoing or cause any third party
            to attempt or engage in any of the foregoing.
          </Li>
        </Ol>
        <H4>Privacy.</H4>
        <P>
          In addition to these Terms, Jeevz’s Privacy Policy, located at
          <a href="https://jeevz.com/privacy">https://jeevz.com/privacy</a>,
          explains the data Jeevz collects, uses, stores, and processes while
          you use and access your Account, the Applications and the Services. By
          using and/or accessing the same, you have read, understood, and agree
          to the terms of such Privacy Policy, and you agree that Jeevz may use
          such data in accordance with the terms of the same and these Terms. If
          you have any questions regarding Jeevz’s Privacy Policy, please
          contact concierge@jeevz.com.
        </P>
        <H4>Communications.</H4>
        <P>
          You agree to receive communications from Jeevz, its affiliated
          companies, and independent contractors, including via e-mail, text
          message, calls, and push notifications. You agree that texts, calls or
          prerecorded messages may be generated by automatic telephone dialing
          systems. Communications from Jeevz, its affiliated companies, and/or
          independent contractors, may include but are not limited to:
          operational communications concerning your Account or use of the
          Services, use of the Applications, updates concerning new and existing
          features, updates to the Terms and other policies, terms and
          statements to which you may be subject, communications concerning
          promotions run by Jeevz, its affiliated companies, independent
          contractors, and news concerning Jeevz and industry developments.
          Standard text messaging charges applied by your mobile device carrier
          will apply to all text messages.
        </P>
        <P>
          YOU ACKNOWLEDGE THAT YOU ARE NOT REQUIRED TO CONSENT TO RECEIVE
          PROMOTIONAL TEXTS OR CALLS AS A CONDITION OF USING THE APPLICATIONS OR
          RELATED SERVICES. IF YOU WISH TO OPT OUT OF PROMOTIONAL EMAILS, YOU
          CAN UNSUBSCRIBE FROM OUR PROMOTIONAL EMAIL LIST BY FOLLOWING THE
          UNSUBSCRIBE OPTIONS IN THE PROMOTIONAL EMAIL ITSELF. IF YOU WISH TO
          OPT OUT OF PROMOTIONAL CALLS OR TEXTS, YOU CAN DO SO BY FOLLOWING THE
          OPTIONS IN THE CALL OR TEXT FROM THE MOBILE DEVICE RECEIVING THE
          MESSAGES. IF YOU WISH TO OPT OUT OF ALL TEXTS OR CALLS FROM JEEVZ
          (INCLUDING OPERATIONAL OR TRANSACTIONAL TEXTS OR CALLS), YOU CAN EMAIL
          THE WORD “UNSUBSCRIBE” TO CONCIERGE@JEEVZ.COM FROM THE MOBILE DEVICE
          RECEIVING THE MESSAGES, HOWEVER YOU ACKNOWLEDGE THAT OPTING OUT OF
          RECEIVING ALL TEXTS MAY IMPACT YOUR USE OF THE APPLICATIONS OR RELATED
          SERVICES, INCLUDING IN AN EMERGENCY. YOU CAN ALSO OTHERWISE CONTACT
          CONCIERGE@JEEVZ.COM FOR FURTHER ASSISTANCE.
        </P>
        <P>
          For additional information, see the Jeevz Privacy Policy located at
          <a href="https://jeevz.com/privacy">https://jeevz.com/privacy</a>.
        </P>
        <H4>Your Information and User Content.</H4>
        <P>
          Your Information is any information you provide, publish or post to or
          through the Applications (including any profile information you
          provide) or send to other Jeevz users or independent contractors
          (including via in-application feedback, any email feature, or through
          any Jeevz-related social media posting) (your “Information”). You
          consent to Jeevz using your Information to create an Account that will
          allow you to use the Applications and Services. Jeevz may, in its sole
          discretion, permit you from time to time to submit, upload, publish or
          otherwise make available to Jeevz textual, audio, and/or visual
          content and information, including commentary and feedback related to
          the Services, initiation of support requests, and submission of
          entries for competitions and promotions (&quot;User Content&quot;).
          You are solely responsible for your Information, User Content and your
          interactions with other users, independent contractors and members of
          the public, and Jeevz acts only as a passive conduit for your
          Information and User Content. You agree to provide and maintain
          accurate, current and complete information and that Jeevz and other
          users, independent contractors and members of the public may rely on
          your Information and User Content as accurate, current and complete.
        </P>
        <P>
          Any Information or User Content provided by you remains your property.
          However, by providing Information or User Content to Jeevz, you grant
          Jeevz a worldwide, perpetual, irrevocable, transferable, royalty-free
          license, with the right to sublicense, to use, copy, modify, create
          derivative works of, distribute, publicly display, publicly perform,
          and otherwise exploit in any manner such Information and User Content
          in all formats and distribution channels now known or hereafter
          devised (including in connection with the Applications, Services and
          Jeevz’s business and on third-party sites and services), without
          further notice to or consent from you, and without the requirement of
          payment to you or any other person or entity.
        </P>
        <P>
          You represent and warrant that: (i) you either are the sole and
          exclusive owner of all Information or User Content or you have all
          rights, licenses, consents and releases necessary to grant Jeevz the
          license to the Information and User Content as set forth above; and
          (ii) neither the Information or User Content, nor your submission,
          uploading, publishing or otherwise making available of such User
          Content, nor Jeevz&#39;s use of the User Content as permitted herein
          will infringe, misappropriate or violate a third party&#39;s
          intellectual property or proprietary rights, or rights of publicity or
          privacy, or result in the violation of any applicable law or
          regulation.
        </P>
        <P>
          You agree to not provide Information or User Content that is
          defamatory, libelous, hateful, discriminatory, racist, violent,
          abusive, profane, obscene, pornographic, sexually explicit, illegal,
          unlawful, or otherwise offensive, as determined by Jeevz in its sole
          discretion, whether or not such material may be protected by law.
          Jeevz may, but shall not be obligated to, review, monitor, or remove
          Information and User Content, at Jeevz’s sole discretion and at any
          time and for any reason, without notice to you.
        </P>
        <P>
          For further information on Jeevz’s collection and use of Information
          and User Content, including personal information, in connection with
          the Applications and Services see its Privacy Policy, located at
          <a href="https://jeevz.com/privacy">https://jeevz.com/privacy</a>.
        </P>
        <H4>User Activity and Behavior.</H4>
        <P>
          When you use the Applications and/or Services, Jeevz keeps track of
          your activity, including the number and types of trips or rides you
          take, the length of those trips or rides and the destinations you
          frequent, and how frequently and for how long you use the Applications
          and Services. Jeevz may also keep track of your interactions with the
          Applications and Services, which may include the features you use, the
          advertising in which you see or show interest, and the content you
          view. Jeevz does this for a variety of reasons, such as to gain an
          understanding of the types of rides or trips or features you and/or
          other similar users tend to take, like or dislike, for checking
          Charges (as defined below), to provide you with relevant and
          interesting advertising, and to improve the Applications and Services
          generally. As this data is essential to the function of the
          Applications and Services, you may not opt out of Jeevz’s collection
          and use of such data or information. By accessing or otherwise using
          any portion of the Applications or Services, you hereby consent to the
          foregoing collection and use of your activity and behavior for the
          purposes set forth above and also as outlined in the Jeevz Privacy
          Policy, located at
          <a href="https://jeevz.com/privacy">https://jeevz.com/privacy</a>.
        </P>
        <H4>Network Access and Devices.</H4>
        <P>
          You are responsible for obtaining the data network access necessary to
          use the Services. Your mobile network&#39;s data and messaging rates
          and fees may apply if you access or use the Services from your device.
          You are responsible for acquiring and updating compatible hardware or
          devices necessary to access and use the Services and Applications and
          any updates thereto. Jeevz does not guarantee that the Services, or
          any portion thereof, will function on any particular hardware or
          devices, or in any particular location or at any particular time. In
          addition, the Services and Applications may be subject to malfunctions
          and delays inherent in the use of the Internet, cellular service and
          electronic communications.
        </P>
        <H4>Representations and Warranties.</H4>
        <P>
          In addition to the other representations and warranties set forth in
          these Terms, you represent, warrant, and agree that:
        </P>
        <Ol>
          <Li>
            You own, or have the legal right to operate and permit other persons
            to operate, the vehicle you use for the Services; such vehicle is in
            good operating condition and meets the industry safety standards and
            all applicable statutory and state department of motor vehicle
            requirements for a vehicle of its kind, and the vehicle has no
            defects or conditions that may cause it to be unsafe to drive; and
            any and all applicable safety recalls have been remedied per
            manufacturer instructions.
          </Li>
          <Li>
            The registration, license tags, and insurance for the vehicle is
            current and valid.
          </Li>
          <Li>
            You have a valid policy of liability insurance (in coverage amounts
            consistent with all applicable legal requirements) that names or
            schedules you (and all other individuals related to the Services, as
            applicable) for the operation of the vehicle you use for Services.
          </Li>
          <Li>
            You possess a valid driver’s license and are authorized and
            medically fit to operate a motor vehicle and have all appropriate
            licenses, approvals and authority to operate a motor vehicle in all
            relevant jurisdictions.
          </Li>
          <Li>
            No contraband, weapons, substance or other items which are illegal
            for public transport, consumption or use are concealed or within the
            vehicle.
          </Li>
          <Li>
            You will not engage in reckless behavior during the Services, act or
            drive unsafely, operate or allow the operation of a vehicle that is
            unsafe to drive, permit an unauthorized third party to accompany you
            in the vehicle during the Services, provide Services or otherwise
            drive or interfere with the provision of the Services while under
            the influence of alcohol or drugs, or take action that harms or
            threatens to harm the safety of the Jeevz users, employees,
            independent contractors or third parties.
          </Li>
          <Li>
            You will only use the Services using a vehicle that has been
            reported to and approved by Jeevz, and for which a photograph has
            been provided to Jeevz and which is registered and listed in your
            Account, and for each Service will identify such an approved vehicle
            as the vehicle to be used and only such vehicle may be used for that
            particular Service; you will not permit more passengers than can
            securely and properly be seated in such vehicle per the vehicle
            manufacturer’s guidelines or instructions and as permitted by
            applicable safety standards and applicable law.
          </Li>
          <Li>
            You will not, during the Services, operate as a public or common
            carrier or taxi service, accept street hails, charge for rides
            (except as expressly provided in these Terms), transact for the
            Services in cash or through a credit card reader to accept or make
            payment or engage in any other activity in a manner that is
            inconsistent with your obligations under these Terms.
          </Li>
          <Li>
            You will not attempt to defraud Jeevz, its independent contractors,
            employees, directors, officers or other users. If Jeevz suspects
            that you have engaged in fraudulent activity it may temporarily or
            permanently suspend or cease the Services or deactivate your Account
            and take any other action against you available under law.
          </Li>
          <Li>
            You agree that we may obtain information about you, including your
            criminal and other records, and you agree to provide any further
            necessary authorizations to facilitate our access to such records.
          </Li>
        </Ol>
        <P>
          In the event that Jeevz believes or determines that you have breached
          any of the foregoing, Jeevz reserves the right to suspend and/or
          permanently deactivate your Account at its sole discretion.
        </P>
        <H4>Compliance with Policies.</H4>
        <P>
          While accessing and/or using your Account, the Applications, and the
          Services, you agree that you will comply with all posted policies as
          Jeevz may update from time to time (whether on its website, through
          the Applications, your Account or otherwise communicated or provided
          to you). Jeevz may suspend or stop providing you with access to your
          Account, the Applications and/or the Services if you fail to comply
          with its posted policies.
        </P>
        <H4>Confidentiality</H4>
        <P>
          You agree not to use any technical, financial, strategic and other
          proprietary and confidential information relating to Jeevz’s business,
          operations and properties, information about a user or driver made
          available to you in connection with such user’s or driver’s use of the
          Applications or Services, which may include their name, personal and
          vehicle information, locations, contact information and photo
          (“Confidential Information”) disclosed to you by Jeevz for your own
          use or for any purpose other than as contemplated herein. You shall
          not disclose or permit disclosure of any Confidential Information to
          third parties, and you agree not to store Confidential Information
          separate and outside of the Applications. You agree to take all
          reasonable measures to protect the secrecy of and avoid disclosure or
          use of Confidential Information in order to prevent it from falling
          into the public domain.
        </P>
        <H3>5. Subscriptions and Charges</H3>
        <H4>Subscriptions and Free Trials.</H4>
        <P>
          Your Jeevz membership subscription (a “Subscription”) is an
          automatically renewing subscription requiring recurring payments and
          will continue until terminated. Except as stated below, to use the
          Applications and Services, you must, among other things, establish an
          Account and provide Jeevz with one or more Payment Methods.
          &quot;Payment Method&quot; means a current, valid, accepted method of
          payment, as may be updated from time to time, and which may include
          payment through your account with a third party. Unless you cancel
          your Subscription before your billing date, you authorize us to charge
          the Subscription fee for the next billing cycle to your Payment
          Method. \ \ Jeevz may offer a number of Subscription plans, including
          special promotional plans or memberships offered by third parties in
          conjunction with the provision of their own products and services.
          Some Subscriptions may have differing conditions and limitations,
          which will be disclosed at your sign-up or in other communications
          made available to you. Each Subscription has an associated periodic
          fee (the “Subscription Fee”). You can find specific details regarding
          your Subscription by visiting Jeevz’s website or by logging into your
          Account.
        </P>
        <P>
          By signing up for a Subscription, creating an Account and/or providing
          a Payment Method, you agree that: (a) you will be charged your first
          Subscription Fee and any applicable taxes on the date you purchase
          your Subscription; (b) you authorize Jeevz and its service providers
          to store your Payment Method for the purpose of executing future
          auto-renewal transactions; (c) UNLESS YOU CANCEL, BY DEFAULT (AND WITH
          PRIOR NOTICE TO THE EXTENT REQUIRED BY APPLICABLE LAW), YOUR
          SUBSCRIPTION WILL AUTOMATICALLY RENEW AT THE END OF THE THEN-CURRENT
          SUBSCRIPTION PERIOD, and (d) AT THE TIME OF RENEWAL, JEEVZ WILL
          AUTOMATICALLY CHARGE THE THEN-CURRENT DASHPASS FEE AND ANY APPLICABLE
          TAXES TO AN ELIGIBLE PAYMENT METHOD THAT JEEVZ HAS ON FILE FOR YOU.
          For further information on payments, see below under “General”.
        </P>
        <P>
          Notwithstanding any other provision in these Terms, you may be
          eligible for a complimentary Subscription when a third party with
          which you have a relationship registers for a commercial or corporate
          Subscription. If you have such a complimentary Subscription, your
          Account will begin and end in accordance with the terms of such
          Subscription. To receive the Subscription benefits, you must register
          an Account using information required by the agreement between Jeevz
          and such third party, and be an authorized user added by the third
          party. The specific terms and conditions of Subscription will
          supplement these Terms and are applicable to you, and you acknowledge
          and agree to be bound by them.
        </P>
        <P>
          Your Jeevz Subscription may start with a free trial. The duration of
          the free trial period of your Subscription will be specified during
          sign-up and is intended to allow new members and certain former
          members to try the Applications and Services. Free trial eligibility
          is determined by Jeevz at its sole discretion and it may limit
          eligibility or duration to prevent free trial abuse. Jeevz reserves
          the right to revoke the free trial and put your Account on hold in the
          event that it determines that you are not eligible. Members of
          households (spouse, parent or child residing in the same home as you)
          with an existing or recent Subscription are not eligible. We may use
          information such as device ID, method of payment or an Account email
          address used with an existing or recent Subscription or Account to
          determine eligibility. For combinations with other offers,
          restrictions may apply. Jeevz will charge the Subscription Fee for the
          next billing cycle to your Payment Method at the end of the free trial
          period unless you cancel your Subscription prior to the end of the
          free trial period.
        </P>
        <P>
          You can cancel your Subscription at any time, and you will continue to
          have access to the Services through the end of your billing period.
          Subscription Fees are non-refundable and Jeevz does not provide
          refunds or credits for any partial periods or unused Services. If you
          cancel your Subscription, your Account will automatically close and
          become deactivated at the end of your current billing period. If you
          signed up for Jeevz using your account with a third party as a Payment
          Method and wish to cancel your Subscription, you may need to do so
          through such third party, for example by visiting your account with
          the applicable third party and turning off auto-renew, or
          unsubscribing from the Jeevz service through that third party. You may
          also find billing information about your Subscription by visiting your
          account with the applicable third party.
        </P>
        <P>
          Jeevz may change its Subscriptions and the Subscription Fees from time
          to time; however, any price changes or other changes to your
          Subscriptions will apply no earlier than 30 days following notice to
          you.
        </P>
        <H4>Other Charges.</H4>
        <P>
          You understand that in addition to the Subscription Fees, use of the
          Services may result in charges to you for the services you receive
          (collectively with the Subscription Fees, &quot;Charges&quot;). Jeevz
          will receive and/or enable your payment of the applicable Charges for
          services obtained through your use of the Services. Charges for fares
          will be variable based on a base charge, where and if applicable, and
          incremental charges based on the duration of the Services. Minimum
          fares may apply. Jeevz uses GPS data where applicable and available,
          the accuracy and availability of which cannot be guaranteed. Charges
          will be inclusive of applicable taxes where required by law. Charges
          may include other applicable fees, tolls, and/or surcharges to the
          extent you do not pay them directly, including booking fees, service
          fees, processing or administration fees, tolls and return tolls,
          parking and event fees, valet charges, airport fees, state or local
          fees, taxes, “prime time”, holiday, busy or high-demand surcharges,
          processing fees, fuel costs, towing or other emergency expenses
          (roadside or otherwise) and any gratuities that you may elect to pay.
          Such Charges may vary and the amounts may not be guaranteed until
          deemed final by Jeevz in its sole discretion. Please visit
          www.jeevz.com/cities for further information on your particular
          location, as applicable. Jeevz has the authority and reserves the
          right to determine and modify pricing by posting applicable pricing
          terms to your market’s page or quoting you a price for a specific ride
          at the time you make a request. Pricing may vary based on the type of
          service you request as described on your market’s page. You are
          responsible for reviewing the applicable market page or price quote
          within the Applications and shall be responsible for all Charges
          incurred under your Account regardless of your awareness of such
          Charges or the amounts thereof.
        </P>
        <H4>General.</H4>
        <P>
          The Subscription Fee will be charged to your Payment Method on the
          specific billing date indicated in your Account. The length of your
          billing cycle will depend on the type of Subscription that you choose
          when you sign-up. In some cases, your payment date may change, for
          example if your Payment Method has not successfully settled or if your
          Subscription began on a day not contained in a given month.
        </P>
        <P>
          All Charges and payments will be enabled by Jeevz using the Payment
          Method designated in your Account. If your primary Account Payment
          Method is determined to be expired, invalid or otherwise not able to
          be charged, you agree that Jeevz may use a secondary Payment Method in
          your Account, if available. If not available, you authorize Jeevz to
          charge any Payment Method associated to your Account. You remain
          responsible for any uncollected amounts. If a payment is not
          successfully settled, due to expiration, insufficient funds, or
          otherwise, and you do not cancel your Subscription or Account, Jeevz
          may suspend your access to the Service until Jeevz has successfully
          charged a valid Payment Method. For some Payment Methods, the issuer
          may charge you certain fees, such as foreign transaction fees or other
          fees relating to the processing of your Payment Method. Local tax
          charges may vary depending on the Payment Method used. Check with your
          Payment Method service provider for details. You can update your
          Payment Methods by going to your Account. Jeevz may also update your
          Payment Methods using information provided by the payment service
          providers. Following any update, you authorize Jeevz to continue to
          charge the applicable Payment Method(s).
        </P>
        <P>
          All Charges are facilitated through a third-party payment processor.
          Jeevz may replace its third-party payment processor without notice to
          you. Charges shall only be made through the Applications. With the
          exception of gratuities, cash payments or through card readers are
          strictly prohibited. Your payment of Charges to Jeevz satisfies your
          payment obligation for your use of the Applications and Services.
          Jeevz may group multiple charges into a single aggregate transaction
          on your payment method based on the date(s) they were incurred. If you
          don&#39;t recognize a transaction, then check your ride receipts and
          payment history.
        </P>
        <P>
          Upon addition of a new payment method or each Service request, Jeevz
          may seek authorization of your selected payment method to verify the
          payment method, ensure the Charges will be covered, and protect
          against unauthorized behavior. The authorization is not a charge,
          however, it may reduce your available credit by the authorization
          amount until your bank’s next processing cycle. Should the amount of
          Jeevz’s authorization exceed the total funds on deposit in your
          account, you may be subject to overdraft or NSF charges by the bank
          issuing your debit or prepaid card. Jeevz shall not be responsible for
          these charges and is unable to assist you in recovering them from your
          issuing bank.
        </P>
        <P>
          Charges (including Subscription Fees) paid by you are final and
          non-refundable, unless otherwise determined by Jeevz in its sole
          discretion. This no-refund policy shall apply at all times regardless
          of your decision to cancel your Subscription, terminate a Service or
          usage of the Applications, any disruption to the Applications or
          Services, or any other reason whatsoever. You will remain liable for
          any other fees or Charges, including for usage of the Services, to be
          paid pursuant to these Terms that have not yet been paid.
        </P>
        <P>
          As between you and Jeevz, Jeevz reserves the right to establish,
          remove and/or revise Charges for any or all services or goods obtained
          through the use of the Services at any time in Jeevz&#39;s sole
          discretion. Further, you acknowledge and agree that Charges applicable
          in certain geographical areas may increase substantially during times
          of high demand or on holidays or for specific events. You will be
          responsible for Charges incurred under your Account regardless of your
          awareness of such Charges or the amounts thereof. Jeevz may from time
          to time provide certain users with promotional offers, coupons,
          credits and discounts that may result in different amounts charged for
          the same or similar services or goods obtained through the use of the
          Services, and you agree that such promotional offers and discounts,
          unless also made available to you, shall have no bearing on your use
          of the Services or the Charges applied to you. After completion of the
          Services, you will have the opportunity to rate your experience and
          leave additional feedback. Jeevz may use the proceeds of any Charges
          for any purpose, subject to any payment obligations it has agreed to
          with any independent contractors or other third parties.
        </P>
        <H4>Promotional Offers, Coupons, Credits and Discounts.</H4>
        <P>
          You may receive promotional offers, coupons, credits and discounts
          that you can apply toward payment of certain Charges upon completion
          of the Service. Such are only valid for use on the Applications and
          for the Services, and are not transferable or redeemable for cash
          except as required by law. Such cannot be combined, and if the cost of
          the Services exceeds the value of the same, Jeevz will charge your
          payment method on file for the outstanding cost of the Services. Jeevz
          may deduct the amount attributable to any Changes (other than the
          applicable fare) before application of the offer, coupon, credit or
          discount. Additional restrictions on offers, coupons, credits and
          discounts may apply as may be communicated to you.
        </P>
        <H4>Service Cancellation and No-Show Fees.</H4>
        <P>
          You may elect to cancel your request for Services at any time prior to
          the commencement of such Services, in which case you may be charged a
          cancellation fee. You may also be charged if you fail to show up after
          requesting the Service or if you fail to comply with these Terms,
          including but not limited to, a failure to provide proof of identity,
          insurance, motor vehicle registration and ownership, safe operating
          condition or otherwise. Please check out Jeevz’s FAQ section on its
          website at www.jeevz.com/FAQ to learn more about Jeevz’s cancellation
          and no-show policy, including applicable fees.
        </P>
        <H4>Damages and Cleaning.</H4>
        <P>
          You shall be responsible for the cost of repair for damage to, or
          necessary cleaning of, vehicles and property resulting from use of the
          Services under your Account.
        </P>
        <H4>Emergencies and Other Incidents.</H4>
        <P>
          You hereby authorize Jeevz and the applicable driver for the Services
          to seek emergency or other help in the event of an emergency or other
          incident, including if you or any individual with you becomes injured
          or ill during the Services. You hereby authorize Jeevz and the
          applicable driver for the Services to leave your vehicle locked and
          parked in a relevant location, such as the hospital or urgent care
          parking lot, in such an event or incident. Your keys will be retained
          by Jeevz until such time as your or an immediate family member or
          other authorized person authorizes their release.
        </P>
        <P>
          In any such event, you further authorize Jeevz and/or the driver for
          the Services to attempt to contact, inform and take instruction from
          any individual listed or associated with your Account, including any
          individual listed as an emergency contact or any member of your
          household (spouse, parent or child residing in the same home as you)
          listed on your Account.
        </P>
        <H3>
          6. Disclaimers; Disclosures; Limitation of Liability; Indemnity.
        </H3>
        <H4>Disclaimers.</H4>
        <P>
          Jeevz does not provide transportation services, and Jeevz is not a
          transportation carrier. Jeevz is not a common carrier or public
          carrier. It is up to you and the driver to decide whether or not to
          request and accept Services. Jeevz cannot ensure that Service will be
          available or completed and it has no control over the quality or
          safety of the transportation that occurs as a result of the Services.
        </P>
        <P>
          The Services are provided &quot;as is&quot; and &quot;as
          available&quot; during the Jeevz hours and days of operation, and
          advanced reservations are required for all Services, including a
          minimum of one (1) hour in advance. For further information regarding
          hours and days of operations, advance reservations and related
          information, please see Jeevz’s FAQ section on its website at
          www.jeevz.com/FAQ. Jeevz disclaims all representations and warranties,
          express, implied, or statutory, not expressly set out in these terms,
          including the implied warranties of merchantability, fitness for a
          particular purpose and non-infringement. In addition, Jeevz makes no
          representation, warranty, or guarantee regarding the accuracy,
          completeness, security, reliability, timeliness, quality, suitability,
          connectivity or availability of the Services or Applications or any
          services or goods requested through the use of the Services or
          Applications, or that the Services or Applications will be available,
          if at all, uninterrupted or error-free, or will meet your
          requirements, or that any defects in the Applications will be
          corrected or that the Applications are free of viruses or other
          harmful components. Jeevz does not guarantee and does not promise any
          specific results from use of the Applications and/or the Services,
          including the ability to provide or receive Services at any given
          location or time, and Jeevz disclaims liability for, and no warranty
          is made with respect to, the accuracy, completeness, security,
          reliability, timeliness, quality, suitability, connectivity or
          availability of the Services or Applications or any services or goods
          requested through the use of the Services or Applications. Jeevz does
          not guarantee the quality, suitability, safety or ability of third
          parties, including independent contractors (e.g., drivers).
        </P>
        <P>
          Jeevz cannot guarantee that each user or driver is who he or she
          claims to be. Please use common sense when using the Applications and
          Services, including looking at the photos of the driver you have
          matched with to make sure it is the same individual you see in person.
          Please note that there are also risks of dealing with underage persons
          or people acting under false pretense, and Jeevz does not accept
          responsibility or liability for any content, communication or other
          use or access of the Applications by persons either under the age of
          18 or otherwise in violation of these Terms. We encourage you to
          communicate directly with each potential driver prior to engaging in
          the Service.
        </P>
        <P>
          Jeevz is not responsible for the conduct, whether online or offline,
          of any user of the Applications or Services or any independent
          contractor. You are solely responsible for your interactions with
          other users and drivers. We do not procure insurance for, nor are we
          responsible for, your personal property left or kept within your motor
          vehicle. By using the Applications and participating in the Services,
          you agree to accept such risks and agree that Jeevz is not responsible
          for the acts or omissions of users on the Applications or
          participating in the Services.
        </P>
        <P>
          You are responsible for the use of your Account and Jeevz expressly
          disclaims any liability arising from the unauthorized use of your
          Account. Should you suspect that any unauthorized party may be using
          your Account or you suspect any other breach of security, you agree to
          notify Jeevz immediately.
        </P>
        <P>
          It is possible for others to obtain information about you that you
          provide, publish or post to or through the Applications (including any
          profile information you provide, Information and User Content), send
          to other users, or share during the Services, and to use such
          information improperly or illegally, including to harass or harm you.
          Jeevz is not responsible for the use of any personal information that
          you disclose to other users on the Application or through the
          Services. Please carefully select the type of information that you
          communicate or post on the Applications or through the Services or
          release or provide to others. Jeevz disclaims all liability,
          regardless of the form of action, for the acts or omissions of other
          users (including unauthorized users, or “hackers”).
        </P>
        <P>
          Opinions, advice, statements, offers, or other information or content
          concerning Jeevz or made available through the Applications or
          Services, but not directly by jeevz, are those of their respective
          authors, and should not necessarily be relied upon. Such authors are
          solely responsible for such content. Under no circumstances will Jeevz
          be responsible for any loss or damage resulting from your reliance on
          information or other content communicated, disclosed, provided or
          posted by third parties, whether on the Application, through the
          Services or otherwise. Jeevz reserves the right, but has no
          obligation, to monitor the materials posted on the Applications and
          remove any such material that in its sole opinion violates, or is
          alleged to violate, the law or the Terms or which might be offensive,
          illegal, or that might violate the rights, harm, or threaten the
          safety of users or others.
        </P>
        <P>
          Location data provided by the Applications is for basic location
          purposes only and is not intended to be relied upon in situations
          where precise location information is needed or where erroneous,
          inaccurate or incomplete location data may lead to death, personal
          injury, property or environmental damage. Neither Jeevz, nor any of
          its content providers, guarantees the availability, accuracy,
          completeness, reliability, or timeliness of location data tracked or
          displayed by the Applications. Any of your Information or User
          Content, including geolocational data, you upload, provide, or post on
          the Applications may be accessible to Jeevz and certain users of the
          Applications.
        </P>
        <P>
          Jeevz advises you to use the Application with a cellular and data plan
          with unlimited or very high data usage limits, and Jeevz shall not be
          responsible or liable for any fees, costs, or overage charges
          associated with any cellular or data plan you use to access the
          Applications and use the Services.
        </P>
        <P>
          You are aware that various risks are involved in the Services. Such
          risks include, among other things, the travel risks associated with
          riding in a vehicle and giving control of a vehicle to another person.
          Despite these and the other risks, you agree to the Service and to
          personally bear such risks, assuming full responsibility for any harm
          or damage that may result.
        </P>
        <P>
          You further agree that the entire risk arising out of or related to
          your use of the Services and Applications remains solely with you to
          the maximum extent permitted under applicable law.
        </P>
        <P>
          Some jurisdictions may not permit the exclusion of certain warranties.
          To the extent applicable local law specifically and expressly
          prohibits such exclusions, those such exclusions set forth above may
          not apply to you.
        </P>
        <H4>Disclosures.</H4>
        <P>
          Certain jurisdictions may require additional disclosures to you. You
          can view any disclosures required by your local jurisdiction at
          www.jeevz.com/cities, as applicable. Jeevz will update the disclosures
          page as jurisdictions add, remove or amend these required disclosures,
          so please check regularly for updates.
        </P>
        <H4>LIMITATION OF LIABILITY.</H4>
        <P>
          JEEVZ SHALL NOT BE LIABLE FOR INDIRECT, INCIDENTAL, SPECIAL,
          EXEMPLARY, PUNITIVE, OR CONSEQUENTIAL DAMAGES, INCLUDING LOST PROFITS,
          LOST DATA, DELETION, CORRUPTION, LOST PROGRAMS, LOST CONNECTIVITY,
          FAILURE TO STORE ANY INFORMATION OR OTHER CONTENT, SERVICE
          INTERRUPTIONS, THE COST OF PROCUREMENT OF SUBSTITUTE SERVICES,
          PERSONAL INJURY, OR PROPERTY DAMAGE RELATED TO, IN CONNECTION WITH, OR
          OTHERWISE RESULTING FROM ANY USE OF THE APPLICATION OR SERVICES, OR
          THE TERMS, HOWEVER ARISING AND REGARDLESS OF THE NEGLIGENCE (EITHER
          ACTIVE, AFFIRMATIVE, SOLE, OR CONCURRENT) OF JEEVZ, EVEN IF JEEVZ HAS
          BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
        </P>
        <P>
          JEEVZ SHALL NOT BE LIABLE FOR ANY DAMAGES, LIABILITY OR LOSSES ARISING
          OUT OF: (i) THESE TERMS; (ii) YOUR USE OF OR RELIANCE ON THE
          APPLICATIONS OR SERVICES OR YOUR INABILITY TO ACCESS OR USE THE
          APPLICATIONS OR SERVICES, INCLUDING DUE TO THE UNAVAILABILITY OF THE
          SERVICES, OR ANY ACTIVITY, INCIDENT, ACCIDENT, OR OTHER MATTER RELATED
          TO OR ARISING OUT OF THE SERVICES; OR (iii) ANY TRANSACTION OR
          RELATIONSHIP BETWEEN YOU AND ANY THIRD PARTY, INCLUDING ANY
          INDEPENDENT CONTRACTOR, EVEN IF JEEVZ HAS BEEN ADVISED OF THE
          POSSIBILITY OF SUCH DAMAGES. JEEVZ SHALL NOT BE LIABLE FOR DELAY OR
          FAILURE IN PERFORMANCE RESULTING FROM CAUSES BEYOND JEEVZ&#39;S
          REASONABLE CONTROL (INCLUDING, LACK OF DRIVER AVAILABILTY, ACCIDENTS,
          INCLEMENT WEATHER, TRAFFIC CONDITIONS, CONSTRUCTION, MECHANICAL ISSUES
          OR SIMILAR SITUATIONS). WITHOUT LIMITING THE FOREGOING, IN THE EVENT
          OF AN ACCIDENT DURING THE SERVICES INVOLVING YOU, THE DRIVER
          PERFORMING THE SERVICES AND YOUR VEHICLE (AS PROPERLY IDENTIFIED FOR
          USE FOR THE SERVICE), AND IT IS DETERMINED BY A FINAL, NON-APPEALABLE
          JUDGMENT THAT THE DRIVER PERFORMING THE SERVICES WAS AT FAULT, JEEVZ
          WILL PAY UP TO $500.00 (FIVE HUNDRED DOLLARS) TOWARDS YOUR INSURANCE
          DEDUCTIBLE.
        </P>
        <P>
          THE APPLICATIONS AND SERVICES MAY BE USED BY YOU TO REQUEST AND
          SCHEDULE TRANSPORTATION SERVICES WITH THIRD PARTIES, INCLUDING
          INDEPENDENT CONTRACTORS, BUT YOU AGREE THAT JEEVZ HAS NO
          RESPONSIBILITY OR LIABILITY TO YOU RELATED TO ANY TRANSPORTATION
          SERVICES PROVIDED TO YOU BY SUCH THIRD PARTIES OTHER THAN AS EXPRESSLY
          SET FORTH IN THESE TERMS.
        </P>
        <P>
          SOME JURISDICTIONS MAY NOT PERMIT THE EXCLUSION OR LIMITATION OF
          CERTAIN LIABILITIES. TO THE EXTENT APPLICABLE LOCAL LAW SPECIFICALLY
          AND EXPRESSLY PROHIBITS SUCH EXCLUSIONS OR LIMITATIONS, THOSE SUCH
          EXCLUSIONS OR LIMITATIONS SET FORTH ABOVE MAY NOT APPLY TO YOU. IN
          SUCH JURISDICTIONS, JEEVZ’S LIABILITY SHALL BE LIMITED TO THE EXTENT
          PERMITTED BY LAW. THIS PROVISION SHALL HAVE NO EFFECT ON JEEVZ’S
          CHOICE OF LAW PROVISION SET FORTH BELOW.
        </P>
        <H4>Indemnity.</H4>
        <P>
          You agree to indemnify and hold Jeevz and its affiliates,
          subsidiaries, parents, successors and assigns, and its and their
          officers, directors, employees, and agents harmless from any and all
          claims, demands, losses, liabilities, and expenses (including
          attorneys&#39; fees), arising out of or in connection with: (i) your
          use of the Applications, Services or any activities related thereto;
          (ii) your breach or violation of any of these Terms or any of the
          documents it incorporates by reference or any other Jeevz terms and
          conditions, policy or statement applicable to you; (iii) Jeevz&#39;s
          use of your Information or User Content, or any allegation that the
          your Information or User Content infringes or otherwise violates the
          copyright, trademark, trade secret or other intellectual property or
          other rights of any third party; (iv) your ownership, use or operation
          of a motor vehicle or passenger vehicle; or (v) your violation of law
          or the rights of any third party, including independent contractors,
          other users, drivers, other motorists, and pedestrians. This indemnity
          shall be applicable without regard to the negligence of any party,
          including any indemnified person.
        </P>
        <H4>Acknowledgment.</H4>
        <P>
          You fully intend and understand that the limitation of liability and
          indemnification provisions of these Terms shall bind you, your family,
          heirs, assigns, or personal representatives. You hereby forever
          release, acquit and discharge Jeevz from any and all liabilities,
          claims, demands, actions and causes of action that you may have by
          reason of any monetary damage or personal injury sustained as a result
          of or during the use of any Service. The sole recourse available to
          you shall be the cancellation of the Subscription and your Account.
        </P>
        <H3>7. Intellectual Property</H3>
        <P>
          All intellectual property rights in the Applications and Services,
          including with respect to JEEVZ and other Jeevz logos, designs,
          graphics, icons, scripts and service names, registered trademarks,
          trademarks or trade dress (collectively, the “Jeevz Marks”), shall be
          owned by Jeevz absolutely and in its entirety. These rights include
          database rights, copyright, design rights (whether registered or
          unregistered), trademarks (whether registered or unregistered) and
          other similar rights wherever existing in the world together with the
          right to apply for protection of the same. If you create any materials
          (physical or digital) bearing the Jeevz Marks (in violation of these
          Terms or otherwise), you agree that upon their creation Jeevz
          exclusively owns all right, title and interest in and to such
          materials, including any modifications to the Jeevz Marks or
          derivative works based on the Jeevz Marks or Jeevz copyrights. You
          further agree to assign any interest or right you may have in such
          materials to Jeevz, and to provide information and execute any
          documents as reasonably requested by Jeevz to enable Jeevz to
          formalize such assignment. All other trademarks, logos, service marks,
          company or product names set forth in the Applications and Services
          are the property of their respective owners. You acknowledge and agree
          that any questions, comments, suggestions, ideas, feedback or other
          information (“Submissions”) provided by you to us are non-confidential
          and shall become the sole property of Jeevz, unless otherwise stated
          herein as it relates to Information or User Content. Jeevz shall own
          exclusive rights, including all intellectual property rights, and
          shall be entitled to the unrestricted use and dissemination of these
          Submissions for any purpose, commercial or otherwise, without
          acknowledgment or compensation to you.
        </P>
        <P>
          You will report any errors, bugs, unauthorized access methodologies or
          any breach of Jeevz intellectual property rights that you uncover in
          your use of the Applications or Services.
        </P>
        <P>
          Jeevz respects the intellectual property of others, and expects you to
          do the same. If you believe, in good faith, that any materials on the
          Applications or through the Services infringe upon your copyrights,
          please view the Jeevz Copyright Policy at www.jeevz.com/legal for
          information on how to make a copyright complaint.
        </P>
        <H3>8. Other Provisions</H3>
        <H4>Other Services.</H4>
        <P>
          In addition to the Services, the Applications may enable you to
          receive services (potentially unrelated to the Services) from other
          third parties (collectively, the “Other Services”). You understand and
          that the Other Services are subject to the terms and pricing of such
          third party. If you choose to purchase Other Services through the
          Applications, you authorize Jeevz to charge your Payment Method on
          file according to the pricing terms set by the third party. You agree
          that Jeevz is not responsible and may not be held liable for the Other
          Services or the actions or omissions of the third party. Such Other
          Services may not be investigated, monitored or checked for accuracy,
          appropriateness, or completeness by Jeevz, and Jeevz is not
          responsible for any Other Services accessed through the Applications.
        </P>
        <H4>Choice of Law.</H4>
        <P>
          These Terms are governed by and construed in accordance with the laws
          of the State of Florida, U.S.A., without giving effect to any conflict
          of law principles, except as may be otherwise provided in Section 2
          above or in supplemental terms applicable to your jurisdiction.
          However, the choice of law provision regarding the interpretation of
          these Terms is not intended to create any other substantive right to
          assert claims under Florida law whether that be by statute, common
          law, or otherwise. This choice of law provision, and except as
          otherwise provided in Section 2 of these Terms, is only intended to
          specify the use of Florida law to interpret these Terms and the forum
          for disputes arising under these Terms.
        </P>
        <H4>Notices.</H4>
        <P>
          Jeevz may give notice by means of a general notice posted on its
          website on in the Applications, electronic mail to your email address
          in your Account, telephone or text message to any phone number
          provided in connection with your Account, or by written communication
          sent by first class mail or pre-paid post to any address connected
          with your Account. Such notice shall be deemed to have been given upon
          the expiration of 48 hours after mailing (if sent by first class mail
          or pre-paid post) or 12 hours after posting (if posting to the
          website) or sending (if sent by email, text message, telephone or
          other electronic means). You may give notice to Jeevz, with such
          notice deemed given when received by Jeevz, at any time by first class
          mail or pre-paid post to Cardinal Fez, Inc., Attn: Secretary, 501 E.
          Las Olas Blvd., Suite 300, Fort Lauderdale, Florida 33301
        </P>
        <H4>General.</H4>
        <P>
          You may not assign these Terms without Jeevz&#39;s prior written
          approval. If any provision of this Agreement is or becomes invalid or
          non- binding, the parties shall remain bound by all other provisions
          of this Agreement. In that event, the parties shall replace the
          invalid or non-binding provision with provisions that are valid and
          binding and that have, to the greatest extent possible, a similar
          effect as the invalid or non-binding provision, given the contents and
          purpose of this Agreement. You agree that this Agreement and all
          incorporated agreements may be automatically assigned by Jeevz, in our
          sole discretion by providing notice to you. Any purported assignment
          in violation of this section shall be void. No joint venture,
          partnership, employment, or agency relationship exists between you,
          Jeevz or any third party, including any independent contractor, as a
          result of this Agreement or use of the Services. If any provision of
          these Terms is held to be invalid or unenforceable, such provision
          shall be struck and the remaining provisions shall be enforced to the
          fullest extent under law, except as may be otherwise provided in
          Section 2 above. Jeevz&#39;s failure to enforce any right or provision
          in these Terms shall not constitute a waiver of such right or
          provision unless acknowledged and agreed to by Jeevz in writing.
          Headings are for reference purposes only and in no way define, limit,
          construe or describe the scope or extent of such section. The words
          “include”, “includes” and “including” are deemed to be followed by the
          words “without limitation”. These Terms, including any additional or
          supplemental terms referenced herein, set forth the entire
          understanding and agreement between you and Jeevz with respect to the
          subject matter hereof and supersedes all previous understandings and
          agreements between the parties, whether oral or written. Unless
          otherwise stated, all provisions contained herein shall survive the
          termination of these Terms.
        </P>
        <P>
          If you have any questions regarding these Terms, your Account, the
          Subscriptions, Applications or Services, please contact Jeevz through
          the FAQ section of the Jeevz website at{" "}
          <a href="https://jeevz.com/FAQ">www.jeevz.com/FAQ</a> or at{" "}
          <a href="mailto:concierge@jeevz.com">concierge@jeevz.com</a>.
        </P>
      </PageWrapper>
    </Layout>
  );
}
